export default {
  loading: 'లోడింగ్...',
  'no-update': 'ఆల్రెడీ ఇది లేటెస్ట్ వర్షన్',
  home: {
    tab: {
      home: 'హోమ్',
      result: 'రిజల్ట్',
      casino: 'కాసినో',
      scratch: 'స్క్రాచ్',
      referral: 'రిఫరల్',
      me: 'మీ',
      live: 'లైవ్',
      liveCasino: 'ప్రత్యక్ష క్యాసినో',
      lottery: 'లాటరీ',
    },
    scratch: {
      free: 'ఫ్రీ',
    },
    kerala: {
      title: 'కేరళ రాష్ట్ర లాటరీ',
      no: 'నో.',
    },
    digit: {
      title: '3 డిజిట్ గేమ్',
      booking: 'తదుపరి బుకింగ్ సమయం',
      ticket: 'టికెట్',
    },
    dice: {
      title: 'డైస్ గేమ్',
      play: 'ప్లే నౌ',
    },
    color: {
      title: 'కలర్ ప్రెడిక్షన్',
    },
    matka: {
      bid: 'బిడ్',
      win: 'విన్',
      open: 'ఓపెన్',
      close: 'క్లోజ్',
      title: 'సట్టా మట్కా',
    },
    'live-casino': {
      title: 'లైవ్ క్యాసినో',
    },
    tip: {
      closed: '{{name}} ఇప్పుడు మూసివేయబడింది, దయచేసి తదుపరి ఆటను ఎంచుకోండి',
      copied: 'కాపీ విజయవంతంగా పూర్తయింది, దయచేసి స్నేహితులతో షేర్ చేయండి',
    },
    label: {
      menu: 'మెను',
      viewAll: 'వీయూ ఆల్',
      buyNow: 'బయ్ నౌ',
      'lucky-spin': 'లకీ స్పిన్',
      rebate: 'రీబేట్',
      'free-lottery': 'ఫ్రీ లాటరీ',
      referral: 'రిఫరల్',
      agent: 'ఏజెంట్',
      promotion: 'ప్రమోషన్',
    },
    luckyspin: {
      add: 'ఆడ్',
      spin: 'స్పిన్',
      won: 'వాన్',
      winner: 'బిగ్ విన్నర్స్',
      mySpin: 'మై స్పిన్',
    },
  },
  me: {
    webview: {
      vip: 'మెంబర్ సెంటర్',
    },
    new: 'న్యూ',
    user: {
      userId: 'యూజర్ ఐడి',
      moreExciting: 'లాగిన్ చేసిన తర్వాత ఇంకా చాలా ఉత్తేజకరం చేస్తుంది',
      login: 'లాగిన్',
      loginUpper: 'లాగిన్',
    },
    money: {
      totalWallet: 'టోటల్ వాలెట్',
      cashBalance: 'క్యాష్ బ్యాలెన్స్',
      withdrawAmount: 'విత్డ్రావాల్ అమౌంట్',
    },
    vip: {
      recharge: 'రీచార్జ్',
      move: 'వీఐపీ 2 చేరుకోవడానికి 1000 కంటే ఎక్కువ రీఛార్జ్ చేయండి',
    },
    bottom: {
      recharge: 'రీచార్జ్',
      withdraw: 'విత్ డ్రా',
      transfer: 'ట్రాన్స్ఫర్',
      proxy: 'ఏజెన్సీ సెంటర్',
      logout: 'లాగ్ అవుట్',
      resultHistory: 'రెసుల్త్ హిస్టరీ',
      myBets: 'మై బెట్స్',
      myTransactions: 'మై ట్రాన్సాక్షన్స్',
      commission: 'మై కమిషన్',
      notify: 'నోటిఫికేషన్స్',
      lang: 'భాషలు',
      customer: 'కస్టమర్ సర్వీస్',
      update: 'అప్డేట్',
      password: 'పాస్‌వర్డ్',
    },
    language: {
      switch: 'భాషను మార్చుకోండి',
    },
    tip: {
      cashTitle: 'క్యాష్ బ్యాలెన్స్:',
      cashContent:
        '"క్యాష్ బ్యాలెన్స్" అనేది మీ ఖాతాలో అందుబాటులో ఉన్న అసలు నగదును సూచిస్తుంది, దీనిని బెట్టింగ్ లేదా ఇతర లావాదేవీల కోసం ఉపయోగించవచ్చు.',
      withdrawTitle: 'విడ్డ్రా అమౌంట్:',
      withdrawContent:
        '"విత్‌డ్రా చేయగల అమౌంట్" అనేది మీ ఖాతా నుండి విత్‌డ్రా చేయగల మీ నగదు బ్యాలెన్స్‌లోని భాగాన్ని సూచిస్తుంది. ఇది పందెం అవసరాలను పూర్తి చేసిన డబ్బును సూచిస్తుంది మరియు నిధులను ఉపసంహరించుకోవడానికి ముందు తీర్చాల్సిన అవసరం ఉంది." తెలుగులో, ఉచ్చారణను సూచించడానికి ఆంగ్ల అక్షరాలను ఉపయోగించి వ్రాయబడింది',
    },
  },
  invitation: {
    title: {
      singUp: 'ఫ్రీ లాటరీ టికెట్లు',
    },
    label: {
      claim: 'మీ టికెట్ను క్లెయిమ్ చేసుకోండి',
    },
  },
  article: {
    detail: {
      title: 'వ్యాసం వివరాలు',
    },
  },
};

export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  home: {
    tab: {
      home: 'Home',
      wallet: 'Wallet',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      scratchOff: 'Scratch Off',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
      liveCasino: 'Live Casino',
      sports: 'Sports',
      lottery: 'Lottery',
      game: 'Games',
      promotions: 'Promotions',
      menu: 'Menu',
      invite: 'Invite Friends',
      promotion: 'Promotion',
      welfare: 'Welfare',
      notification: 'Message',
    },
    scratch: {
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      open: 'OPEN',
      close: 'CLOSE',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    car: {
      title: 'Quick Race',
      min: 'min',
      text: 'Next draw starts in',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
    },
    label: {
      menu: 'Menu',
      viewAll: 'View All',
      buyNow: 'Buy Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Bet Rebate',
      'free-lottery': 'Free Lottery',
      referral: 'Referral',
      referFriend: 'Refer Friend',
      agent: 'Agent',
      promotion: 'Promotion',
    },
    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
  },
  me: {
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
      registerUpper: 'REGISTER',
    },
    money: {
      totalWallet: 'Total Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result History',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer Service',
      update: 'Update',
      password: 'Password',
      download: 'Install APP',
      games: 'My Games',
      collect: 'My Collect',
      shop: 'Shop',
      coupon: 'My Coupon',
      giftCode: 'Gift Code',
    },
    language: {
      switch: 'Switch Language',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
    },
  },
  homeMenu: {
    title: {
      profile: 'My Profile',
      password: 'Set Password',
      notification: 'My Notification',
      bets: 'My Bets',
      wallet: 'My Wallet',
      transactions: 'My Transactions',
      history: 'Result History',
      rebate: 'Bet Rebate',
      center: 'Agency Center',
      game: 'My Game',
      invite: 'Invite Friends',
      vipclub: 'VIP Club',
      luckyspin: 'Lucky Spin',
      freelottery: 'Free Lottery',
      collect: 'Collect',
    },
  },
  invitation: {
    title: {
      singUp: 'Free lottery tickets',
    },
    label: {
      claim: 'Claim your Ticket',
    },
  },
  article: {detail: {title: 'Article Detail'}},
  agentfranchise: {detail: {title: 'Agent Franchise'}},
  vipclub: {detail: {title: 'VIP Club'}},
};
